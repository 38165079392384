.home-header { 
    width: 100%;
}

.home-header-innerdiv {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 6px;
}

.header-title {
    position: absolute;
    left: 50%;
    font-weight: bold;
    color: #00a2ff;
    font-size: 20px;
}
.header-left {
    text-transform:uppercase;
}

.header-left-item  {
    padding: 0px 6px;
    font-weight: bold;
}
.header-left-item:hover {
    background-color: #3385b6;
    border-radius: 4px;
    cursor: pointer;
}
.header-right-signUp ,.header-right-login  {
    border-radius: 4px;
    cursor: pointer;
}

.header-right-signUp {
    font-weight: bold;
    font-size: 14px;
    padding: 8px;
    margin: 0px 2px;
    background-color: #5fb94c;
}
.header-right-signUp:hover {
    background-color: #2c9717;
}
.header-right-login {
    font-weight: 500;
    font-size: 14px;
    padding: 8px;
    margin: 0px 2px;
    background-color: #4a93bd;
}
.header-right-login:hover {
    background-color: #00a2ff;
}