* {
  box-sizing: border-box;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #6da2c7;
  border-radius: 10px;
  background-color: #1067a3;
  
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* background-color: #1067a3; */
  margin-bottom: 20px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #1067a3;
  background-color: #6da2c7;
}

.home {
  width: 100vw;
  height: 100vh;
  color: white;
  background-color: #172b4d;
}

.flex {
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .header,
.home .appBar,
.home .userProfile,
.home .popover,
.home .tasksDragger {
  width: 100%;
}

.home .header,
.home .appBar
{
  height: 6%;
  max-height: 6%;
}
.home .userProfile  {
  height: 8%;
  max-height: 8%;
}

.home .header {
  background-color: #1067a3;
}
.home .appBar {
  color: black;
  background-color: #faf3c0;
}
.home .userProfile {
  background-color: #1479bf;
}
.home .popover {
  background-color: #1479bf;
}
.home .tasksDragger {
  height: 80%;
  max-height: 80%;
  overflow: hidden;
  background-color: #1479bf;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 500px) {
  .home .header,
  .home .appBar,
  .home .userProfile {
    display: none;
    width: 0%;
  }

  .home .popover {
    height: 10%;
    max-height: 10%;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.75);
    box-shadow: -1px 8px 15px -1px rgba(0, 0, 0, 0.75);
  }
  .home .tasksDragger {
    height: 90%;
    max-height: 90%;
    overflow: hidden;
   
  }
}

@media (min-width: 500px) {
  .home .popover {
    display: none;
  }
}
