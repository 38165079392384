.home-popover {
    width: 100%;
}

.home-popover-innerDiv {
  display: flex;    
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}   

.home-popover-left {
    font-weight: bold;
}
.home-popover-right {
    padding: 8px;
    background-color: #3d99ce;
    border-radius: 4px;
}
.home-popover-right:hover {
    cursor: pointer;
    background-color: #0c70aa;
}

.home-popover .sidenav {
    height: 100%;
    width: 70%;
    position: fixed; 
    z-index: 1;
    bottom: 0;
    background-color: rgb(11, 98, 156); 
    overflow-x: hidden; 
    transition: all 0.5s;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.home-popover .sidenav-open {
    right: 0px;
  }
  
  .home-popover  .sidenav-close {
    right: -70%;
  }